export default {
    namespaced: true,
    state: {
        user_id: null,
        login_name: null,
        name: '',
        email:'',
        tel: '',
        backTo: '',
    },
    getters: {
        user_id(state) {
            return state.user_id
            // return state.scenarios.find((s) => {s.id == id})
        },
        login_name(state) {
            return state.login_name
            // return state.scenarios.find((s) => {s.id == id})
        },
        name(state) {
            return state.name
            // return state.scenarios.find((s) => {s.id == id})
        },
        email(state) {
            return state.email
            // return state.scenarios.find((s) => {s.id == id})
        },
        tel(state) {
            return state.tel
            // return state.scenarios.find((s) => {s.id == id})
        },
        admin(state) {
            return state.admin == 1
            // return state.scenarios.find((s) => {s.id == id})
        },
        dump(state) {
            return state
        },
        backTo(state) { return state.backTo },
    },
    mutations: {
        customer_data: function (state, payload) {
            state.user_id = payload.user_id
            state.login_name = payload.login_name
            state.name = payload.name
            state.email = payload.email
            state.tel = payload.tel
        },
        clear: function(state) {
            state.user_id = null
            state.login_name = null
            state.name = ''
            state.email = ''
            state.tel = ''
        },
        setBackTo: function (state, backTo) {
            state.backTo = backTo
        },
    },
    actions: {
    },
}
