import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import customer from './customer'

export default createStore({
  plugins: [createPersistedState({
    key: 'faChatbotSystem',
    paths: [
      'scenario',
      'scenarioPlay',
      'customer',
      'editView',
    ],
    storage: window.sessionStorage,
  }),
  ],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    customer,
  }
})
