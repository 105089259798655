<template>
  <router-view />
</template>

<style>
@media screen and (min-width: 479px) {
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  * {
    box-sizing: border-box;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  body {
    background-color: #edeff2;
  }
}

@media screen and (max-width: 479px) {
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  * {
    box-sizing: border-box;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
}
</style>
